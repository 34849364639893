import React, { Component } from "react";
import { Container, Carousel } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReadMoreAndLess from "read-more-and-less";

export default class FNews extends Component {
  static defaultProps = {
    imageslider: false,
    videoimageslider: false,
  };

  render() {
    const {
      image,
      badge,
      title,
      text,
      imageslider,
      simage1,
      simage2,
      videoimageslider,
      svideo1,
    } = this.props;

    // Логика для изображений, создаем массив только с существующими изображениями
    const CarouselImages = imageslider ? [simage1, ...(simage2 ? [simage2] : [])] : [];

    // Логика для видео
    const CarouselVideos = videoimageslider ? [svideo1] : [];
    const isVideo = CarouselVideos.length > 0;

    // Если включен слайдер изображений
    if (imageslider) {
      return (
        <>
          <Container className="mt-5">
            <div className="d-md-flex flex-md-row row bg-light">
              <div className="col-md-6 mb-4 mt-4">
              <Carousel style={{ width: "400px", height: "auto" }} interval={isVideo ? null : 5000}>
                  {CarouselImages.length > 0 &&
                    CarouselImages.map((image, index) => (
                      <Carousel.Item key={index}>
                        <div
                          className="bg-image hover-overlay ripple shadow-2-strong rounded-5"
                          data-mdb-ripple-color="light"
                        >
                          <LazyLoadImage
                            src={image}
                            effect="blur"
                            width={"400px"}
                            height="auto"
                            placeholderSrc="https://via.placeholder.com/400x300"
                          />
                        </div>
                      </Carousel.Item>
                    ))}
                  {CarouselVideos.length > 0 &&
                    CarouselVideos.map((video, index) => (
                      <Carousel.Item key={index}>
                        <div className="bg-video hover-overlay ripple shadow-2-strong rounded-5">
                          <video width="400" height="auto" controls>
                            <source src={video} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      </Carousel.Item>
                    ))}
                </Carousel>
              </div>
              <div className="col-md-6 mb-4 mt-4">
                <span className="badge bg-danger px-2 py-1 shadow-1-strong mb-3">
                  {badge}
                </span>
                <h4>
                  <strong>{title}</strong>
                </h4>
                <ReadMoreAndLess
                  text={text}
                  charLimit={200}
                  readMoreText="Читать далее"
                  readLessText="Скрыть"
                />
              </div>
            </div>
          </Container>
        </>
      );
    } else {
      return (
        <>
          <Container className="mt-5">
            <div className="d-md-flex flex-md-row row bg-light">
              <div className="col-md-6 mb-4 mt-4">
                <div
                  className="bg-image hover-overlay ripple shadow-2-strong rounded-5"
                  data-mdb-ripple-color="light"
                >
                  <LazyLoadImage
                    src={image}
                    effect="blur"
                    width={"400px"}
                    placeholderSrc="https://via.placeholder.com/400x300"
                  />
                </div>
              </div>
              <div className="col-md-6 mb-4 mt-4">
                <span className="badge bg-danger px-2 py-1 shadow-1-strong mb-3">
                  {badge}
                </span>
                <h4>
                  <strong>{title}</strong>
                </h4>
                <ReadMoreAndLess
                  text={text}
                  charLimit={200}
                  readMoreText="Читать далее"
                  readLessText="Скрыть"
                />
              </div>
            </div>
          </Container>
        </>
      );
    }
  }
}
