import React, { Component } from 'react';
import FNews from '../Compoments/FNews';

/*
image = src (изображение новости)
badge = название префикса
title = заголовок новости
text = текст новости
imageslider: true - активация слайдера с изображениями (image = src не надо указывать),
simage1: require('') - первое изображение слайдера,
simage2: require('') - второе изображение слайдера,
*/

export default class News extends Component {
  render() {
    const news1 = {
      image: require('../Pages/Images/News/news8.jpg'),
      badge: '17.04.2022',
      title: 'Здравоохранение Урала 2022',
      text: 'Торжественное награждение участников выставки "Здравоохранение Урала 2022". Награжден наш врач-хирург Сабадаш Андрей Владимирович.',
    };
    const news2 = {
      image: require('../Pages/Images/News/news6.jpg'),
      badge: '03.08.2022',
      title: 'Риск инфицирования послеоперационной раны 99%',
      text: 'Перевязка ран в домашних условиях - обеспечивает инфицирование послеоперационной раны в 99% случаев. Почему не 100% - потому что 1% - это уже инфицированная послеоперационная рана еще до перевязки. На изображении - скобочные швы - в хирургической практике встречаются крайне редко - по причине высокой стоимости и особенности снятия.'
    };
    const news3 = {
      image: require('../Pages/Images/News/news5.jpg'),
      badge: '24.08.2022',
      title: 'Достижения сотрудников',
      text: 'Врач хирург Сабадаш Андрей Владимирович на 3-ей Международной специализированной выставке-форуме "Здравоохранение Урала 2022" представлял достижения хирургической службы Первой областной клинической больнице в Экспо-центре - Министру Здравоохранения Российской Федерации Мурашко М.А'
    };
    const news4 = {
      image: require('../Pages/Images/News/news4.jpg'),
      badge: '17.09.2022',
      title: 'Международный день хирурга.',
      text: 'Клиника послеоперационного периода Хирург и Я поздравляет всех причастных к хирургии с Международным днём Хирурга!'
    };
    const news5 = {
      image: require('../Pages/Images/News/news3.jpg'),
      badge: '03.11.2022',
      title: 'Как это оперировать?',
      text: '3 ноября с грандиозным успехом прошла конференция хирургов Свердловской области. Наш врач Сабадаш Андрей Владимирович провел мастер класс на примере рецидивной паховой грыже. Труд Андрея Владимирович очень ценится в кругу хирургов! Поздравляем и гордимся талантами наших врачей.'
    };
    const news6 = {
      image: require('../Pages/Images/Profiles/profile1.jpg'),
      badge: '09.11.2022',
      title: 'Новый сотрудник!',
      text: 'В нашей клинике начал вести прием Шушанов Антон Павлович - Заведующий хирургическим отделением реконструктивной гастроэнтерологии и абдоминальной хирургии. Врач-хирург высшей категории с опытом работы более 20 лет. Врач УЗИ, онколог. Хирург первой величины - профессионал в хирургии.'
    };
    const news7 = {
      image: require('../Pages/Images/about.jpg'),
      badge: '16.11.2022',
      title: 'Пациент - в приоритете!',
      text: 'Cостоялась пресс-конференция в Уральском региональном центре ТАСС. Наш врач-хирург Сабадаш Андрей Владимирович, выступал совместно с главным хирургом УрФО Михаилом Иосифовичем Прудковым. Пресс-конференция посвящена обсуждению концепции непрерывного обучения хирургов в Свердловской области. Андрей Владимирович является одним из преподавателей непрерывного обучения хирургов на базе СОКБ №1.'
    };
    const news8 = {
      image: require('../Pages/Images/News/news1.jpg'),
      badge: '21.11.2023',
      title: 'Точно в цель!',
      text: 'Выполнение пункции жидкостных скоплений послеоперационных ран под УЗИ наведением.'
    };
    const news9 = {
      image: require('../Pages/Images/News/news0.jpeg'),
      badge: '03.12.2022',
      title: 'Возвращаем к жизни без боли!',
      text: 'Инъекции современных препаратов в суставы всех локализаций. Внутрисуставные инъекции являются неотъемлемой частью консервативного лечения при воспалительных и дегенеративных заболеваниях опорно-двигательной системы. Уколы в пораженный сустав помогают снять воспаление и купировать даже очень сильную боль при артрите и артрозах. Метод устранения суставных болей будет зависеть от причины, по которой они возникают. Наряду с медикаментозным лечением для ускорения выздоровления сустава могут быть назначены и внутрисуставные инъекции, которые быстро снимают воспаление и устраняют болевой синдром.'
    };
    const news10 = {
      image: require('../Pages/Images/Profiles/profile2.jpg'),
      badge: '15.12.2022',
      title: 'Новый сотрудник!',
      text: 'В нашей клинике начинает вести прием врач-хирург-колопроктолог главной больницы Свердловской области Камилов Магомед Юсупович. Все что деликатно, тревожно и важно - теперь можно решить в нашей клинике.'
    };
    const news11 = {
      image: require('../Pages/Images/News/news7.jpg'),
      badge: '28.12.2022',
      title: 'Человек года!',
      text: 'Наш главный врач получил заслуженную награду "Человек года" 2022 в Свердловской областной клинической больнице №1.'
    };
    const news12 = {
      image: require('../Pages/Images/News/graph.jpg'),
      badge: '20.08.2023',
      title: 'Изменен график работы!',
      text: 'Наша клиника теперь работает по новому графику! Вт-Пт с 14:00 до 21:00. Сб с 14:00 до 20:00.'
    };
    const news13 = {
      image: require('../Pages/Images/News/news9-1.jpg'),
      badge: '21.09.2023',
      title: 'Синдром диабетической стопы (СДС)',
      text: 'Синдром диабетической стопы (СДС) определяется как инфекция, язва и/или деструкция тканей стопы, связанная с неврологическими расстройствами или снижением магистрального кровотока различной степени тяжести у пациентов с сахарным диабетом. Как профилактировать, перевязывать и лечить вы узнаете на консультации у хирурга Сабадаш А.В Одним из важных аспектов – применение специальных перевязочных материалов – альгинатов, атравматичных сетчатых повязок с мазью, полиуретановых губчатых повязок с серебром. В нашей клинике есть полный спектр лечебных перевязочных материалов, которые к сожалению, отсутствуют в обычных больницах.'
    };
    const news14 = {
      image: require('../Pages/Images/News/news10.jpg'),
      badge: '21.09.2023',
      title: 'Подготовка к пациенту',
      text: 'Каждый рабочий день начинается с подготовки к каждому приходящему пациенту – консультативная помощь это важно, но что если нужно провести манипуляцию или операцию – для этого у нас в клиники есть весь спектр необходимого материала и инструментов – даже самых маленьких для ювелирной работы.'
    };
    const news15 = {
      image: require('../Pages/Images/News/news11.jpg'),
      badge: '21.09.2023',
      title: 'Пункция жидкостных скоплений',
      text: 'Пункция жидкостных скоплений под УЗИ наведением – это опасно? – ответ находится в самом вопросе – когда манипуляция проводится под контролем УЗИ датчика – можно наблюдать весь процесс онлайн, что минимизирует риск осложнений – На фото и видео видно, как игла проходит через кожные покровы и попадает в полость с жидкостью – откуда ее выкачивают шприцом через тоненькую иголочку. (масштаб 1:100)'
    };
    const news16 = {
      image: require('../Pages/Images/gal12.jpg'),
      badge: '22.09.2023',
      title: 'Мы в социальных сетях!',
      text: 'Посетите нашу страницу во Вконтакте: https://vk.com/klinika.khirurgiya и в Телеграм: https://t.me/klinika_khirurgiya'
    };
    const news17 = {
      image: require('../Pages/Images/News/news12.jpg'),
      badge: '01.11.2023',
      title: 'ПроДокторов 1 место!',
      text: 'Спешим поздравить Главного врача Клиники «Хирург и Я» Сабадаш Андрея Владимировича, занявшего 1 место среди хирургов Свердловской области по мнению пациентов 💥🥇💥 Благодарим каждого пациента, кто оставил отзыв- ведь именно ваши слова и отзывы помогают другим пациентам сделать правильный выбор! Гордимся 💫 и желаем Андрею Владимировичу не останавливаться на достигнутом ☄️ С уважением коллектив Клиники «Хирург и Я»'
    };
    const news18 = {
      image: require('../Pages/Images/News/news13.jpg'),
      badge: '11.12.2023',
      title: '💥Проконсультируйтесь с врачом, не выходя из дома!💥',
      text: 'Уважаемые пациенты, рады вам сообщить, что мы запускаем Online - консультацию у хирурга по ссылке: https://goo.su/Ksw8t0. Online - консультация в Клинике "Хирург и Я"📲 '
    };
    const news19 = {
      image: require('../Pages/Images/News/news14.jpg'),
      badge: '11.12.2023',
      title: 'Хирурга, при необходимости, можно пригласить на дом для осмотра больного 📅🚀',
      text: 'Такой осмотр поможет предположить или поставить верный диагноз; объяснить тактику ведения пациента в дальнейшем; профессионально уточнить информацию по уходу. При необходимости доктор даст направление в стационар, в том числе в Клинику "Хирург и Я" Заявку на выезд хирурга на дом можно оставить по телефону +7(922)101-911-2 📲'
    };
    const news20 = {
      image: require('../Pages/Images/News/news18.jpg'),
      badge: '16.11.2023',
      title: 'Образовательная конференция',
      text: '💫16 и 17 ноября прошла образовательная конференция от Продокторов. Коллектив Клиники "Хирург и Я" принял участие ✅ Получены новые знания, будем выводить медицину на космический уровень 🚀'
    };
    const news21 = {
      image: require('../Pages/Images/News/news19.jpg'),
      badge: '18.11.2023',
      title: 'Лица Хирургии - Светлаков Георгий Петрович 📸',
      text: 'Нашу Клинику посетил начальник медицинской части по хирургии Центральной городской больницы №7 - Светлаков Георгий Петрович 💫 Андрей Владимирович и Георгий Петрович обсудили возможности внедрения инновационных технологий при лечении гнойных ран в "Хирург и Я" Мы получили полезные советы и рекомендации - для нас это очень ценно! Благодарим за визит 🤝💥 С заботой о пациенте, "Хирург и Я"'
    };
    const news22 = {
      image: require('../Pages/Images/News/news20.jpg'),
      badge: '19.11.2023',
      title: 'Лица Хирургии - Галимзянов Фарид Вагизович 📸',
      text: 'Нашу клинику посетил известнейший хирург с большой буквы - заведующий отделением гнойной хирургии первой областной больницы, доктор медицинских наук, профессор кафедры хирургических болезней УГМУ Галимзянов Фарид Вагизович - доктор рассказал об особенностях ведения гнойных ран в условиях Клиники "Хирург и Я", а также поприсутствовал на перевязке непростого клинического случая заболевания пациента - с тактикой он согласился и привнес несколько дополнительных методик для скорейшего выздоровления пациентов. От всего сердца благодарим его за визит в нашу клинику. С уважением к пациенту, с любовью к хирургии!'
    };
    const news23 = {
      image: require('../Pages/Images/News/news21.jpg'),
      badge: '01.12.2023',
      title: '💥БЕСПЛАТНАЯ ХИРУРГИЧЕСКАЯ ПОМОЩЬ УЧАСТНИКАМ СВО💥',
      text: '‼️Максимальный репост‼️ До конца этого года, Клиника "Хирург и Я" готова бесплатно принять пациентов - участников СВО ⚔️ Российские военнослужащие демонстрируют храбрость и героизм, долг наших врачей оказать адекватную хирургическую помощь! Мы рады вам помочь 🤝'
    };
    const news24 = {
      image: require('../Pages/Images/News/news23.jpg'),
      badge: '30.12.2023',
      title: '💫🎄🔥Заряженные ждем пациентов🔥🎄💫',
      text: 'Сегодня коллектив Клиники "Хирург и Я" поднимет каждому пациенту Новогоднее настроение ✨ Мы подготовили вот такие подарки для вас! Ценим за выбор Клиники "Хирург и Я" С уважением к пациенту, с любовью к хирургии! ✨',
    };
    const newsslider1 = {
      imageslider: true,
      simage1: require('../Pages/Images/News/snews1.jpg'),
      simage2: require('../Pages/Images/News/snews2.jpg'),
      badge: '30.12.2023',
      title: 'ОПЕРАЦИЯ ПО УДАЛЕНИЮ МЕТАЛЛОКОНСТРУКЦИЙ ПОСЛЕ ОСТЕОСИНТЕЗА ПЕРЕЛОМОВ В «ХИРУРГ И Я»',
      text: 'Удаление металлоконструкций является плановой операцией, которую проводят после консолидации (сращения) перелома. Это выполняется в амбулаторных условиях, под местной анестезией. К нам Вы можете записаться на удаление спиц, позиционных винтов, пластин, аппарата Илизарова. Наш доктор, Липин Глеб Игоревич, выполняет эту манипуляцию максимально комфортно и быстро! Телефон для записи +7(922)101-911-2'
    };
    const news25 = {
      image: require('../Pages/Images/gal19.jpg'),
      badge: '01.03.2024',
      title: 'Первый день весны!',
      text: '💐Команда клиники Хирург и Я, поздравляет с первым днем весны. Желаем вам крепкого здоровья, процветания. 💐',
    };
    const newsslider2 = {
      imageslider: true,
      simage1: require('../Pages/Images/News/snews3.jpg'),
      simage2: require('../Pages/Images/News/snews4.jpg'),
      badge: '06.03.2024',
      title: '✨Инновации в «Хирург и Я»✨',
      text: 'Малоинвазивные операции с использованием ЭХВЧ - электрохирургического высокочастотного аппарата! 🚀 Электрохирургия это современное направление, которое имеет преимущества: ✅ точность действия - размер рабочего инструмента составляет меньше толщины скальпеля. ✅ ⁠бескровность - при использовании ЭХВЧ происходит слипание тканей с кровеносными капиллярами, что гарантирует отсутствие кровопоттеков и синяков. ✅ ⁠маневренность - угол работы рабочего инструмента 360 градусов, в отличии от обычных хирургических инструментов. ✅ ⁠быстрое заживление ран - на фоне отсутствия гематом, синяков, уменьшается и отечность раны в раннем послеоперационном периоде, что ведет к быстрому восстановлению кожных покровов. ✅ ⁠антибиотики стоп ⛔️ - при использовании ЭХВЧ снижается риск инфицирования раны даже при проведении перевязок в домашних условиях, в связи с чем- антибактериальная терапия не нужна.'
    };
    const news26 = {
      image: require('../Pages/Images/News/news24.jpg'),
      badge: '21.03.2024',
      title: 'Очень приятно видеть такую сильную школу хирургии , как в «Хирург и Я»',
      text: 'Старшая медицинская сестра Областной больницы и старшая медицинская сестра в Клинике «Хирург и Я». Это второй человек после Главного врача. К ее компетенции относятся административно-хозяйственные вопросы, касающиеся работы Клиники. Елена Александровна контролирует проведение асептических и антисептических мероприятий в процедурных и перевязочных кабинетах Клиники. Занимается обеспечением санитарно-гигиеническими и перевязочными материалами, медикаментами в нашей Клинике. С такой командой - мы уверены в безопасности вашего лечения',
    };
    const news27 = {
      image: require('../Pages/Images/News/news25.jpg'),
      badge: '03.05.2024',
      title: 'Гилёва Елена Александровна',
      text: 'Данный хирург в представлении не нуждается, но все же: Шушанов Антон Павлович , заведующий хирургическим отделением «Первой областной клинической больницы», хирург Клиники послеоперационного периода «Хирург и Я» Известнейший мастер своего дела, опытнейший хирург, который берется даже за самые сложные случаи. Только доверяя и зная Сабадаш Андрея Владимировича, как опытного врача и отличного хирурга - ведет прием бок-о-бок с ним.',
    };
    const news28 = {
      image: require('../Pages/Images/News/news26.jpg'),
      badge: '03.05.2024',
      title: 'Отзывы и рекомендации',
      text: 'Более тысячи довольных пациентов вышли из нашей клиники 💥. 💯 пациентов приходят по рекомендации в поисках качественного лечения! 👍 Мы безусловно рады такой статистике ❤️💙💛💚. Будем рады видеть Вас в лице наших пациентов🤝',
    };
    const news29 = {
      image: require('../Pages/Images/News/news27.jpg'),
      badge: '03.05.2024',
      title: 'Филющенко Никита Алексеевич',
      text: 'Студент 6 курса лечебно-профилактического факультета УГМУ и медицинский брат Клиники "Хирург и Я". Работая в команде с ведущими хирургами города, Никита набирается опыта для своего развития, как врача-хирурга. Многообразие клинических случаев и знания старших коллег помогает сформировать мышление молодого специалиста. В нашей Клинике Никита участвует в перевязках ран различной сложности, выполняет внутримышечные инъекции, а самое главное, ассистирует на операциях врачам различных специальностей: хирургам, травматологу и колопроктологу. Сабадаш Андрей Владимирович, главный врач Клиники "Хирург и Я", подготавливает молодых специалистов к оказанию качественной хирургической помощи населению г. Екатеринбурга.',
    };
    const news30 = {
      image: require('../Pages/Images/News/news28.jpg'),
      badge: '03.05.2024',
      title: 'Липин Глеб Игоревич',
      text: 'Травматолог - ортопед из Свердловского областного онкологического диспансера. В Клинику "Хирург и Я" Вы можете обратиться к врачу по следующим вопросам: ✅ консультативный прием, ✅ удаление гигромы, ✅ удаление металлофиксаторов, ✅ внутрисуставные инъекции, ✅ наложение/снятие гипса и др.',
    };
    const news31 = {
      image: require('../Pages/Images/News/news29.jpg'),
      badge: '03.05.2024',
      title: 'Продолжаем знакомство с коллективом Клиники «Хирург и Я»',
      text: '💥Белкина Полина - студентка Свердловского Областного медицинского колледжа факультета сестринское дело. Полина проходит ознакомительную практику и после выпуска продолжит работу в Клинике «Хирург и Я» ✅ Направление хирургия - для Полины новое, но она с большим энтузиазмом осваивает новые навыки и знания! Будучи студентом Полина уверенна в своем будущем - ведь точно знает, что ее ждёт работа в команде профессионалов!',
    };
    const news32 = {
      image: require('../Pages/Images/News/news30.jpg'),
      badge: '03.05.2024',
      title: 'Камилов Магомед Юсупович',
      text: 'Врач хирург-колопроктолог Областной больницы (отделение абдоминальной онкологии и колопроктологии) и врач хирург-колопроктолог Клиники "Хирург и Я". 💥Колопроктолог специализируется на диагностике и лечении расстройств, связанных с толстым кишечником и аноректальной областью.',
    };
    const news33 = {
      image: require('../Pages/Images/News/news31.jpg'),
      badge: '09.05.2024',
      title: 'Дадашев Малик Рафикович',
      text: '✅ Спешим рассказать Вам, что в нашей команде новый хирург. Дадашев Малик Рафикович - врач-хирург Областной больницы, отделения хирургии печени. ✅ Основными направлениями, которыми занимается доктор: Первичный рак печени, Метастатическое поражение печени, Кисты печени, Опухоли желчных протоков, Желчнокаменная болезнь, Паразитарные заболевания (эхинококкоз, альвеококкоз), Гемангиомы печени, Другие объемные образования печени, Опухоли поджелудочной железы. ✅ Наша команда хирургов становится все больше и мы уверены, что сможем оказать качественную хирургическую помощь любого профиля. ✅ Доктор ведет приемы по средам и пятницам - запись по телефону +7922-101-911-2💫',
    };
    const news34 = {
      image: require('../Pages/Images/News/news32.jpg'),
      badge: '01.07.2024',
      title: 'Клиника "Хирург и Я" ✨',
      text: 'У нас в команде, не только самые лучшие врачи Свердловской области, но и лучшие медицинские сестры! 💥Искренне поздравляем медицинскую сестру Белкину Полину Константиновну с окончание медицинского колледжа с отличием!💯',
    };
    const newsslider3 = {
      imageslider: true,
      simage1: require('../Pages/Images/News/snews5.jpg'),
      simage2: require('../Pages/Images/News/snews6.jpg'),
      badge: '03.07.2024',
      title: 'Медицина',
      text: 'В Клинике "Хирург и Я" можно приобрести перевязочный материал. ✅ Стоимость и наличие уточняйте у администратора ✨',
    };
    const news35 = {
      image: require('../Pages/Images/News/news33.jpg'),
      badge: '04.07.2024',
      title: 'Наши поздравления!',
      text: 'Коллектив Клиники "Хирург и Я" поздравляет Филющенко Никиту Алексеевича с получением диплома врача. 📘Главный врач, Сабадаш Андрей Владимирович, продолжит быть наставником для молодого специалиста в хирургической службе! ✨Желаем Никите активного и уверенного старта в должности врача. 🚀💯💫',
    };
    const newsslider4 = {
      imageslider: true,
      videoimageslider: true,
      simage1: require('../Pages/Images/News/snews7.jpg'),
      svideo1: require('../Pages/Images/News/snews7.mp4'),
      badge: '13.08.2024',
      title: 'Клинике «Хирург и Я» 2 года🥳',
      text: 'Клиника «Хирург и Я» - нам 2 года 🫶 В августе 2022г Клиника послеоперационного периода «Хирург и Я» открыла свои двери и встретила первых пациентов. За эти два года мы приняли более чем 1300 пациентов 📈 Получили более 200 положительных отзывов на различных площадках 💌 Расширили спектр услуг и манипуляций🚀 В нашей команде стало больше высококлассных специалистов (врачи, мед сестры, администраторы) 👨🏻‍⚕️👩🏻‍⚕️🧑🏻‍💻 Наш коллектив решил провести этот день с пользой - мы посетили прекрасное место Котокафе "Больше шерсти!" Екатеринбург 🐾Подарили пушистым друзьям внимание и заботу, привезли необходимые медикаменты, игрушки, корм и классно провели время ✨',
    };
    const news36 = {
      image: require('../Pages/Images/News/news35.jpg'),
      badge: '03.09.2024',
      title: '💥Новости Клиники "Хирург и Я" 💥',
      text: 'В нашей команде новый сотрудник - администратор Анна 🌷 Доброжелательная, отзывчивая, внимательная и ответственная ✨ Именно с такими качествами работают администраторы в нашей клинике 💙Мы понимаем, что хирургическое вмешательство не всегда приятная процедура, поэтому хотим, чтобы посещение врача-хирурга оставило у вас только положительные эмоции 💫 Наши девочки-администраторы создают максимально комфортные условия 😊 Мы рады вам помочь✨',
    };
    const newsslider5 = {
      imageslider: true,
      simage1: require('../Pages/Images/News/snews8.jpg'),
      simage2: require('../Pages/Images/News/snews9.jpg'),
      badge: '04.09.2024',
      title: 'Клиника "Хирург и Я" выходит на новый уровень! 💫📈',
      text: '🔔Вашему вниманию представляем инновационный метод лечения хронических, незаживающих ран, в особенности с раневым истощением - 🔔 - Методика PRP терапия✅ PRP-терапия (от англ. Platelet Rich Plasma – обогащенная тромбоцитами плазма). 🚀Простыми словами, введение определенных элементов собственной крови именно в незаживающие края раны, раны с раневым истощением, когда рана перестает заживать и остается на одном месте - без положительной, либо отрицательной динамики - после введения данной плазмы - происходит возобновление регенерации тканей с последующим полным заживлением раны. ✅Если у вас есть незаживающая рана, либо заживление заняло уже длительное время - мы можем вам помочь 💯',
    };
    return (
      <div>
        <FNews {...newsslider5}/>
        <FNews {...news36}/>
        <FNews {...newsslider4}/>
        <FNews {...news35}/>
        <FNews {...newsslider3}/>
        <FNews {...news34}/>
        <FNews {...news33}/>
        <FNews {...news32}/>
        <FNews {...news31}/>
        <FNews {...news30}/>
        <FNews {...news29}/>
        <FNews {...news28}/>
        <FNews {...news27}/>
        <FNews {...news26}/>
        <FNews {...newsslider2}/>
        <FNews {...news25}/>
        <FNews {...newsslider1}/>
        <FNews {...news24}/>
        <FNews {...news23}/>
        <FNews {...news22}/>
        <FNews {...news21}/>
        <FNews {...news20}/>
        <FNews {...news19}/>
        <FNews {...news18}/>
        <FNews {...news17}/>
        <FNews {...news16}/>
        <FNews {...news15}/>
        <FNews {...news14}/>
        <FNews {...news13}/>
        <FNews {...news12}/>
        <FNews {...news11}/>
        <FNews {...news10}/>
        <FNews {...news9}/>
        <FNews {...news8}/>
        <FNews {...news7}/>
        <FNews {...news6}/>
        <FNews {...news5}/>
        <FNews {...news4}/>
        <FNews {...news3}/>
        <FNews {...news2}/>
        <FNews {...news1}/>
      </div>
    );
  }
}